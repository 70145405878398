const dataMainTitle = {
  title: "ТОРГОВА МАРКА «РАДОКОРМ» ПРЕДСТАВЛЕНА ДВОМА ЛІНІЙКАМИ: ЖОМ ТА МЕЛЯСА",
  textButton: "Залишити заявку",
};

const dataAboutUs = {
  title: "dataAboutUs.title",
  titleContent: "dataAboutUs.titleContent",
  description_1: "dataAboutUs.description_1",
  description_2: "dataAboutUs.description_2",
  imgUrl: process.env.PUBLIC_URL + "/media/images/aboutUs/decor_1.png",
};

const dataProduct = {
  title: "dataProduct.title",

  content: [
    {
      // title: "МЕЛЯСА",
      title: "dataProduct.content.obj_1.title",
      imgUrl: process.env.PUBLIC_URL + "/media/images/products/melassa.png",
      titleButton: "dataProduct.content.obj_1.titleButton",
      path: "/molasses",
    },
    {
      title: "dataProduct.content.obj_2.title",
      imgUrl: process.env.PUBLIC_URL + "/media/images/products/gom.png",
      titleButton: "dataProduct.content.obj_2.titleButton",
      path: "/compost",
    },
  ],
};

const dataDescription = {
  title_1: "dataDescription.title_1",
  title_2: "dataDescription.title_2",
  message: {
    obj_1: {
      title: "dataDescription.message.obj_1.title",
      description: "dataDescription.message.obj_1.description",
    },

    obj_2: {
      title: "dataDescription.message.obj_2.title",
      description: "dataDescription.message.obj_2.description",
    },

    obj_3: {
      title: "dataDescription.message.obj_3.title",
      description: "dataDescription.message.obj_3.description",
    },
  },
};

const dataPartners = {
  title: "Наші партнери 11",
  partners: [
    {
      title: "Західний буг",
      imgUrl: process.env.PUBLIC_URL + "/media/icons/partners/partner_1.svg",
      imgUrlColor:
        process.env.PUBLIC_URL + "/media/icons/partners/partnerColor_1.svg",
      // linkURL: 'https://www.mcdonalds.com/ua/uk-ua.html',
    },
    {
      title: "Агрологістика",
      imgUrl: process.env.PUBLIC_URL + "/media/icons/partners/partner_2.svg",
      imgUrlColor:
        process.env.PUBLIC_URL + "/media/icons/partners/partnerColor_2.svg",
      // linkURL: 'https://nor-estagro.com/',
    },
  ],
};

const dataContacts = {
  title_1: "Де можна придбати жом",
  title_2: "Де можна придбати мелясу",
  name_1: "Жом",
  name_2: "Меляса",

  arrLocations: [
    {
      id: 1,
      title_1: "Радехівське",
      title_2: "виробництво",
      location:
        "//www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d326837.16299464455!2d24.427866123731707!3d50.20945864314207!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47253b25b3829adf%3A0x6d968253a378e5d4!2z0KLQt9Ce0JIg0KDQsNC00LXRhdGW0LLRgdGM0LrQuNC5INGG0YPQutC-0YA!5e0!3m2!1suk!2sua!4v1705932386074!5m2!1suk!2sua",
      packagingMolas: [
        {
          weight: "6 кг",
          imgUrl:
            process.env.PUBLIC_URL + "/media/icons/packaging/bucket_S.png",
        },
        {
          weight: "22 кг",
          imgUrl:
            process.env.PUBLIC_URL + "/media/icons/packaging/bucket_M.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/train.png",
        },
      ],
      packagingCompost: [
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck_2.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/train.png",
        },
      ],
    },
    {
      id: 2,
      title_1: "Чортківське",
      title_2: "виробництво",
      location:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10470.264748230515!2d25.8517817!3d48.9998125!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4731745c19621483%3A0x6fab61a1a8e3a5ae!2sThe%20Limited%20Liability%20Company%20%22Radekhivskiy%20sugar%22!5e0!3m2!1suk!2sua!4v1705932580789!5m2!1suk!2sua6",
      packagingMolas: [
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/train.png",
        },
      ],
      packagingCompost: [
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck_2.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/train.png",
        },
      ],
    },
    {
      id: 3,
      title_1: "Збаразьке",
      title_2: "виробництво",
      location:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2587.8310240878973!2d25.5899225!3d49.5632114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47302eaaa29c3949%3A0xdcbf5890548d5db0!2zRGlhbWFudCDQptGD0LrRgNC-0LLQuNC5INCX0LDQstC-0LQ!5e0!3m2!1suk!2sua!4v1705932717542!5m2!1suk!2sua",
      packagingMolas: [
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck.png",
        },
      ],
      packagingCompost: [
        // {
        //   weight: "6 кг",
        //   imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/bag_M.png",
        // },
        // {
        //   weight: "22 кг",
        //   imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/bag_M.png",
        // },
        // {
        //   weight: "20 т",
        //   imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck.png",
        // },
      ],
    },
    {
      id: 4,
      title_1: "Козівське",
      title_2: "виробництво",
      location:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d20759.452356330283!2d25.1617785!3d49.4291082!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473043a10ba0dcb3%3A0x1466c82928fa9f78!2z0KLQt9Ce0JIg0KDQsNC00LXRhdGW0LLRgdGM0LrQuNC5INGG0YPQutC-0YA!5e0!3m2!1suk!2sua!4v1705932752484!5m2!1suk!2sua",
      packagingMolas: [
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/train.png",
        },
      ],
      packagingCompost: [
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck_2.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/train.png",
        },
      ],
    },
    {
      id: 5,
      title_1: "Хоростківське",
      title_2: "виробництво",
      location:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d20852.558803609056!2d25.8990684!3d49.2087191!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4731a3322e77e0cf%3A0xa486221543c3237d!2z0KLQt9Ce0JIg0KDQsNC00LXRhdGW0LLRgdGM0LrQuNC5INGG0YPQutC-0YA!5e0!3m2!1suk!2sua!4v1705932787064!5m2!1suk!2sua",
      packagingMolas: [
        {
          weight: "6 кг",
          imgUrl:
            process.env.PUBLIC_URL + "/media/icons/packaging/bucket_S.png",
        },
        {
          weight: "22 кг",
          imgUrl:
            process.env.PUBLIC_URL + "/media/icons/packaging/bucket_M.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/train.png",
        },
      ],
      packagingCompost: [
        {
          weight: "22 кг",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/bag_S.png",
        },
        {
          weight: "40 кг",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/bag_M.png",
        },
        {
          weight: "1т",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/bag_XXX.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck_2.png",
        },
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/train.png",
        },
      ],
    },
    {
      id: 6,
      title_1: "Гнідавське",
      title_2: "виробництво",
      location:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d20203.732063261046!2d25.3276887!3d50.7298359!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47259a2300000001%3A0xcf66941d8f34acfe!2z0JPQndCG0JTQkNCS0KHQrNCa0JjQmSDQptCj0JrQoNCe0JLQmNCZINCX0JDQktCe0JQ!5e0!3m2!1suk!2sua!4v1705932823170!5m2!1suk!2sua",
      packagingMolas: [
        {
          weight: "",
          imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/truck.png",
        },
      ],
      packagingCompost: [],
    },
  ],
};

const dataInfo = {
  title: "Детальніше про умови та вартість продукції за номером:",
  number: "+38 050 431 27 91",
  name: "Владислав",
  imgUrl: process.env.PUBLIC_URL + "/media/images/aboutUs/decor_1.png",
};

const dataFooter = {
  imgUrl: process.env.PUBLIC_URL + "/media/images/aboutUs/decor_1.png",
};

const dataNavigation = {
  header: [
    {
      id: 1,
      name: "dataNavigation.header.obj_1.name",
      path: "/compost",
    },
    {
      id: 2,
      name: "dataNavigation.header.obj_2.name",
      path: "/molasses",
    },
    {
      id: 3,
      name: "dataNavigation.header.obj_3.name",
      path: "https://kombikorm.radocorm.com.ua/",
    },
    {
      id: 4,
      name: "dataNavigation.header.obj_4.name",
      path: "https://radsugar.com/",
    },
  ],

  footer: [
    {
      id: 0,
      name: "dataNavigation.footer.obj_1.name",
      path: "/",
    },
    {
      id: 1,
      name: "dataNavigation.footer.obj_2.name",
      path: "/compost",
    },
    {
      id: 2,
      name: "dataNavigation.footer.obj_3.name",
      path: "/molasses",
    },
    {
      id: 3,
      name: "dataNavigation.footer.obj_4.name",
      path: "https://kombikorm.radocorm.com.ua/",
    }
  ],
};

const dataMainDescriptionMolasses = {
  title: "Опис",
  description_1: "Меляса бурякова",
  description_2:
    " — це продукт, отриманий після кристалізації цукру з концентрованого цукрового соку, витягнутого з коренів цукрових буряків (Beta vulgaris L.). Продукт в’язкий, темний, солодкий, насичений цукром із карамельним смаком. Мелясу бурякову дуже полюбляє худоба. Це основний кормовий інгредієнт для всіх видів худоби, включаючи птицю. Він використовується як джерело енергії, як сполучна речовина в комбікормах і як носій для інших інгредієнтів, таких як джерела небілкового азоту (наприклад сечовина).",
};

const dataMainDescriptionCompost = {
  title: "Опис",
  description_1: "Жом із цук­ро­вого бу­ряка",
  description_2:
    " — це корм, який має лег­кі цук­ри, кліт­ко­вину, гар­но роз­щеплю­єть­ся, зні­має теп­ло­вий стрес і мо­же ста­ти бо­нусом у скла­дан­ні ра­ці­ону для тварин. Особливе місце займають умови зберігання жому, саме тому складські приміщення повинні бути обладнані вентиляцією з контролем відносної вологості повітря.",
};

const dataStorageConditions = {
  title: "Умови зберігання",
  imgUrlDecor:
    process.env.PUBLIC_URL + "/media/images/storageConditions/decor_1.png",
  imgUrl:
    process.env.PUBLIC_URL +
    "/media/images/storageConditions/girlSugarTablet.png",
  text: "Особливе місце займають умови зберігання, саме тому складські приміщення обладнані вентиляцією з контролем відносної вологості повітря, яка не повинна перевищувати більше ніж 80 %.",
};

const dataPackagingCompost = {
  title: "Пакування",
  logoUrl: process.env.PUBLIC_URL + "/media/images/packaging/logo_1.png",

  container: {
    title: "Зручне пакування у мішках",
    content: [
      {
        title: "У мішках",
        weight: "22кг",
        imgUrl: process.env.PUBLIC_URL + "/media/images/packaging/bag_S.png",
      },
      {
        title: "У мішках",
        weight: "40кг",
        imgUrl: process.env.PUBLIC_URL + "/media/images/packaging/bag_M.png",
      },
      {
        title: "У мішках",
        weight: "1т",
        imgUrl: process.env.PUBLIC_URL + "/media/images/packaging/bag_XXX.png",
      },
    ],
  },

  transport: {
    title: "Вагоном та вантажним автомобілем",
    content: [
      {
        title: "Вантажікою",
        weight: "",
        imgUrl: process.env.PUBLIC_URL + "/media/images/packaging/truck_1.png",
      },
      {
        title: "Вагонами",
        weight: "",
        imgUrl: process.env.PUBLIC_URL + "/media/images/packaging/train.png",
      },
    ],
  },

  description: {
    title: "Пакування та Мінімальне замовлення",
    internal: {
      imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/decoExport.png",
      title: "Внутрішній ринок",
      description_1: "Фасована продукція: ≥ 22 т",
      description_2: "Вантажний автомобіль: ≥ 22 т",
    },
    external: {
      imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/decoImport.png",
      title: "Експорт",
      description_1: "≥ 500 т",
    },
  },
};

const dataPackagingMolas = {
  title: "Пакування",

  container: {
    title: "Зручне пакування у відрах",
    content: [
      {
        title: "У відрах",
        weight: "6кг",
        imgUrl:
          process.env.PUBLIC_URL + "/media/images/packaging/bucket_green_s.png",
      },
      {
        title: "У відрах",
        weight: "22кг",
        imgUrl:
          process.env.PUBLIC_URL + "/media/images/packaging/bucket_green_m.png",
      },
    ],
  },

  transport: {
    title: "Вагоном та автоцистерною",
    content: [
      {
        title: "Вагоном",
        weight: "",
        imgUrl:
          process.env.PUBLIC_URL + "/media/images/packaging/train_yell.png",
      },
      {
        title: "Автоцестерною",
        weight: "",
        imgUrl: process.env.PUBLIC_URL + "/media/images/packaging/truck.png",
      },
    ],
  },

  description: {
    title: "Пакування та Мінімальне замовлення",
    internal: {
      imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/decoImportW.png",
      title: "Внутрішній ринок",
      description_1: "Фасована продукція: ≥ 1 т",
      description_2: "Автоцистерна: ≥ 22 т",
    },
    external: {
      imgUrl: process.env.PUBLIC_URL + "/media/icons/packaging/decoExportW.png",
      title: "Експорт",
      description_1: "≥ 500 т",
    },
  },
};

const slideCompostData = [
  {
    title: "Жом буряковий",
    subtitle: "Використовується як корм та кормова добавка для годівлі тварин",
    media: "/media/images/slider/gom2.mp4",
  },
];

const detailsCompost = {
  title1: "Органолептичні показники",
  title2: "Фізико-хімічні показники",
  img: "/media/images/compost/gomFoto.png",
  organ: [
    {
      title: "Колір",
      subtitle: "СІРИЙ",
    },
    {
      title: "Запах",
      subtitle: "Специфічний, без інших сторонніх запахів",
    },
  ],

  him: [
    {
      title: ">0,76 %",
      subtitle:
        "Загальна енергетична цінність сушеного жому, виражена в кормових одиницях: не менше ніж",
    },
    {
      title: ">14,0 %",
      subtitle: "Масова частка вологи, не більше ніж",
    },
    {
      title: ">10,0 %",
      subtitle: "Масова частка незгранульованого жому, %, не більше ніж",
    },
    {
      title: "Не нормують",
      subtitle:
        "Масова частка сахарози (в перерахунку на суху речовину), %, не менше ніж",
    },
    {
      title: ">7,0 %",
      subtitle:
        "Масова частка сирого протеїну (в перерахунку на суху речовину), %, не менше ніж",
    },
    {
      title: ">0,0 %",
      subtitle:
        "Масова частка карбаміду, що добавляють у розчин з мелясою, %, не більше ніж",
    },
    {
      title: "НЕ ДОЗВОЛЕНО",
      subtitle:
        "Вміст металомагнітних домішок розмірами понад 2 мм і частинок з гострими краями, мг/кг, не більше ніж",
    },
    {
      title: ">1,5 %",
      subtitle: "Масова частка механічних домішок, %, не більше ніж",
    },
    {
      title: ">100",
      subtitle:
        "Вміст металомагнітних домішок розмірами до 2 мм включ., мг/кг, не більше ніж",
    },
  ],
};

const slideMolasData = [
  {
    title: "Меляса бурякова",
    subtitle:
      "Використовується як сировина для виробництва біоетанолу, харчових кислот, хлібопекарських та кормових дріжджів, як харчова добавка для корму тварин, а також в хлібопекарській галузі",
    media: "/media/images/slider/molas1.mp4",
  },
];

const whatMolasData = {
  title: "ДЛЯ ЧОГО ВИКОРИСТОВУЄТЬСЯ МЕЛЯСА?",
  content: [
    {
      img: "/media/images/molasses/mosal1.svg",
      text: "Виробництво етилового спирту",
    },
    {
      img: "/media/images/molasses/mosal2.svg",
      text: "Виробництво харчових кислот",
    },
    {
      img: "/media/images/molasses/mosal3.svg",
      text: "Виробництво хлібопекарських та кормових дріжджів",
    },
    {
      img: "/media/images/molasses/mosal4.svg",
      text: "Добавка до корму сільськогосподарських тварин",
    },
  ],
};

const detailsMolas = {
  title1: "Органолептичні показники",
  title2: "Фізико-хімічні показники",
  img: "/media/images/molasses/mosal-detail.png",
  organ: [
    {
      title: "Зовнішній вигляд",
      subtitle: "Густа, в’язка, непрозора рідина",
    },
    {
      title: "Колір",
      subtitle: "Від коричневого до темно-бурого",
    },
    {
      title: "Запах",
      subtitle: "Властивий буряковоцукровій мелясі без стороннього запаху",
    },
    {
      title: "Смак",
      subtitle: "Солодкий з гіркуватим присмаком",
    },
    {
      title: "Розчинність",
      subtitle:
        "Повна, розчиняється у будь-яких співвідношеннях у гарячій і холодній воді",
    },
  ],

  him: [
    {
      title: ">75,0 %",
      subtitle: "Масова частка сухих речовин, %, не менше",
    },
    {
      title: ">43,0 %",
      subtitle: "Масова частка сахарози, %, не менше",
    },
    {
      title: ">44,0 %",
      subtitle: "Масова частка суми цукрів, що зброджуються, %, не менше",
    },
    {
      title: "6,5 — 8,5",
      subtitle: "Величина pH",
    },
  ],
};

const orderForm = {
  title: "orderForm.title",
  title_2: "orderForm.title_2",
  title_3: "orderForm.title_3",
  title_4: "orderForm.title_4",
  title_5: "orderForm.title_5",
  title_6: "orderForm.title_6",
  imgUrl: process.env.PUBLIC_URL + "/media/images/aboutUs/decor_1.png",

  termsCooperation: {
    title: "orderForm.termsCooperation.title",
    arrContent: [
      {
        title: "orderForm.termsCooperation.arrContent.el_1.title",
        description: "orderForm.termsCooperation.arrContent.el_1.description",
      },
      {
        title: "orderForm.termsCooperation.arrContent.el_2.title",
        description: "orderForm.termsCooperation.arrContent.el_2.description",
      },
    ],
  },
  typeProduct: {
    title: "orderForm.typeProduct.title",
    products: [
      {
        name: "orderForm.typeProduct.products.el_1.name",
        media: process.env.PUBLIC_URL + "/media/images/products/gom.png",
      },
      {
        name: "orderForm.typeProduct.products.el_2.name",
        media: process.env.PUBLIC_URL + "/media/images/products/melassa.png",
      },
    ],

    typePackaging: {
      title: "orderForm.typeProduct.typePackaging.title",
      arrPackaging_1: [
        {
          name: "orderForm.typeProduct.typePackaging.arrPackaging_1.el_1.name",
        },
        {
          name: "orderForm.typeProduct.typePackaging.arrPackaging_1.el_2.name",
        },
      ],

      arrPackaging_2: [
        {
          name: "orderForm.typeProduct.typePackaging.arrPackaging_2.el_1.name",
        },

        {
          name: "orderForm.typeProduct.typePackaging.arrPackaging_2.el_2.name",
        },
      ],
    },

    typeContainer: {
      title_1: "orderForm.typeProduct.typeContainer.title_1",
      title_2: "orderForm.typeProduct.typeContainer.title_2",

      arrContainer_1: [
        {
          name: "orderForm.typeProduct.typeContainer.arrContainer_1.el_1.name",
        },
        {
          name: "orderForm.typeProduct.typeContainer.arrContainer_1.el_2.name",
        },
        {
          name: "orderForm.typeProduct.typeContainer.arrContainer_1.el_3.name",
        },
      ],

      arrContainer_2: [
        {
          name: "orderForm.typeProduct.typeContainer.arrContainer_2.el_1.name",
        },

        {
          name: "orderForm.typeProduct.typeContainer.arrContainer_2.el_2.name",
        },
      ],
    },
  },
};

const allData = {
  dataMainTitle,
  dataAboutUs,
  dataProduct,
  dataDescription,
  dataPartners,
  dataContacts,
  dataInfo,
  dataFooter,
  dataNavigation,
  dataMainDescriptionMolasses,
  dataMainDescriptionCompost,
  dataStorageConditions,
  dataPackagingCompost,
  dataPackagingMolas,
  slideCompostData,
  detailsCompost,
  slideMolasData,
  whatMolasData,
  detailsMolas,
  orderForm,
};

export default allData;
